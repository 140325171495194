@import './custom.scss';
@import url("https://fonts.googleapis.com/css2?family=Titillium+Web:wght@400;700&display=swap");

html {
  scroll-behavior: smooth;
}

body {
  text-align: center;
  font-family: "Titillium Web", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: #ffffff;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.app {
  height: 100vh;
}

.navbar {
  .page-active {
    text-decoration: underline;
    text-underline-offset: 6px;
  }
}

.pagex {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.app-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .app-logo {
    animation: app-logo-spin infinite 20s linear;
  }
}

.app-header {
  font-size: calc(10px + 2vmin);
  color: #000000;
}

.app-link {
  /* border: #0a66c2 1px solid; */
  svg {
    color: #0a66c2;
    filter: drop-shadow(2px 2px 1px #000);
  }
  img {
    height: 48px;
    filter: drop-shadow(2px 2px 1px #000);
  }
}

.portfolio {
  --bs-bg-opacity: .1;
}

table td {
  text-align: left;
}

.btn-up {
  opacity: 0.5;
  &:hover {
    opacity: 1;
  }
}

@keyframes app-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
